import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@components/Link";
import { makeStyles } from "@material-ui/core/styles";
import PublicPage from "@components/PublicPage";
import Container from "@material-ui/core/Container";
import Footer from "@components/Homepage/Footer";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Image from "next/image";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50
  },
  box: {
    width: "700px",
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  typography: {
    marginTop: "10px",
    textAlign: "center",
  },
}));

function Error404() {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <PublicPage>
      <div style={{ minHeight: "75vh" }}>
        <Container maxWidth="sm">
          <Box mt={7} mx={9}>
            <Image src="/illustrations/404.svg" height={500} width={500} />
            {/* <div>
              <object
                type="image/svg+xml"
                alt="Error 404"
                data="/illustrations/404.svg"
                // style={{ height: "100%" }}
              />
            </div> */}
          </Box>
        </Container>
        <Grid container component="main" className={classes.root}>
          <Box bgcolor="background.paper" className={classes.box}>
            {/* <Typography variant="h1" color="primary">
              404
            </Typography> */}
            <Typography variant="h4" color="textSecondary">
              {t("page_not_found")}
            </Typography>
            <Typography color="textSecondary" className={classes.typography}>
              {t("page_not_found_info")}{" "}
            </Typography>
            <Button
              color="primary"
              onClick={() => {
                router.push("/");
              }}
            >
              {t("return_to_homepage")}
            </Button>
          </Box>
        </Grid>
      </div>

      <Footer />
    </PublicPage>
  );
}

export default Error404;
